<template>
    <div class="level_index level_indexII">
        <el-dialog :close-on-click-modal="false" title="异常报备" :visible.sync="show" width="500px">
            <!--  -->

            <!-- <div class="info">
                医院负责人你好：
                <br />
                截止到2023年04月25日，贵院已经连 续72小时未产生医废联单。
                <br />
                请上报贵院未产生医废的原因：
            </div> -->


            <el-form ref="form" label-width="80px">

                <el-form-item label="所属医院" prop="">
                    <el-select v-model="uploadWarn.dep_id" placeholder="请选择医院">
                        <el-option :label="item.name" :value="item.id" v-for="item in depList"
                            :key="item.id"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="标题" prop="">
                    <el-input v-model="uploadWarn.title" />
                </el-form-item>

                <el-form-item label="报备信息" prop="">
                    <el-input v-model="uploadWarn.info" />
                </el-form-item>

                <el-form-item label="状态" prop="">
                    <el-radio v-model="uploadWarn.state" :label="0">未处理</el-radio>
                    <el-radio v-model="uploadWarn.state" :label="1">已处理</el-radio>
                </el-form-item>

            </el-form>



            <div class="radio">

                <el-radio v-model="uploadWarn.type" :label="1">暂时停业</el-radio>

                <div v-show="uploadWarn.type == 1">
                    开始：
                    <el-date-picker v-model="uploadWarn.start_time" type="date" format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd" placeholder="停业开始时间" class="SelfSelect">
                    </el-date-picker>
                    结束：
                    <el-date-picker v-model="uploadWarn.end_time	" type="date" format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd" placeholder="停业结束时间" class="SelfSelect">
                    </el-date-picker>

                    <br />
                    <br />
                </div>

                <el-radio v-model="uploadWarn.type" :label="2">未产生医废</el-radio>
                <el-radio v-model="uploadWarn.type" :label="3">医院关停</el-radio>

            </div>

            <!--  -->


            <span slot="footer">
                <div class="buttons">
                    <el-button type="primary" @click="save">保存</el-button>
                    <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
                </div>
            </span>



        </el-dialog>
    </div>
</template>

<script>
    import {
        mapState,
        mapMutations
    } from 'vuex'

    export default {
        data() {
            return {
                show: false,

                radio: '',

                time: '',

                depList: [],

                uploadWarn: {
                    dep_id: "",
                    title: "",
                    info: "",
                    state: "",
                    type: "",
                    start_time: "",
                    end_time: ""
                },

                isEdit: false

            }
        },
        computed: {
            ...mapState('m_item', ['LevelList', 'PositionList', 'DepList', 'HealthList', 'Province', 'City', 'District',
                'RoleList', 'Depcate', 'ItemType'
            ]),
        },
        watch: {

            show(value) {
                if (this.show) {

                    if (this.depList.length == 0) {
                        this.getdep()
                        setTimeout(() => {
                            this.depList = this.DepList
                        }, 1000)
                    }

                } else {
                    this.isEdit = false

                    this.uploadWarn = {
                        dep_id: "",
                        title: "",
                        info: "",
                        state: "",
                        type: "",
                        start_time: "",
                        end_time: ""
                    }

                }
            },

        },

        methods: {
            open() {
                this.formShow = true
                this.show = true
            },

            refresh() {
                this.show = false
                this.$emit('refresh')
                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            },

            edit(row) {

                this.uploadWarn = {
                    ...row
                }
                this.isEdit = true

                this.open()
            },

            save() {

                if (this.isEdit) {

                    this.$http.put('/api/hazardousabnormal/' + this.uploadWarn.id, this.uploadWarn).then((res) => {
                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                        if (res.code == 200) {

                            this.refresh()
                        }

                        if (res.code != 200) { //请求错误
                            this.$message.error(res.msg);

                        }
                    }).catch((err) => {
                        console.log(err)
                    });

                } else {

                    this.$http.post('/api/hazardousabnormal', this.uploadWarn).then((res) => {
                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                        if (res.code == 200) {

                            this.refresh()
                        }

                        if (res.code != 200) { //请求错误
                            this.$message.error(res.msg);

                        }
                    }).catch((err) => {
                        console.log(err)
                    });

                }

            }

        },

    }
</script>

<style scoped lang="scss">
    /*  .selfInputBoxIV .el-input__inner[placeholder="请选择配置分组"],
  .selfInputBoxIV .el-input__inner[placeholder="请选择配置类型"] {
    border: 1px red solid !important;
    box-sizing: content-box !important;
  } */
    /deep/.el-dialog__body {
        padding: 20px 20px 30px 20px;
    }

    .info {
        // border: 1px red solid;
        // box-sizing: content-box;
        width: 90%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        font-size: 14px;
        font-weight: 400;
        color: #626262;
        line-height: 36px;
    }

    .radio {
        width: 50%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        .el-radio {
            display: block;
        }

        .SelfSelect {
            width: 100%;
        }
    }
</style>